import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"

import { colors } from "../../styles/design.config"
import {
  changeMerchantCode,
  getInputMaskMerchantImpactedDocuments,
  toggleBlockedByAlreadyTakenMerchantCode,
} from "../../store/ducks/inputMask.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { Select } from "../Commons/Select"
import { ReactComponent as Edit } from "../../assets/buttons/edit.svg"
import { ReactComponent as Lock } from "../../assets/lock.svg"
import { getIdFromParams } from "../../utils/company"
import { MerchantModificationModal } from "./MerchantModificationModal"
import { reinitializeMerchantModification } from "../../store/ducks/merchantModification.ducks"

export const MerchantCodeSelectCell = () => {
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const [isModalDisplayed, setIsModalDisplayed] = useState(false)

  const {
    merchantCodes,
    editedFullDocument,
    initialFullDocument,
    deactivatedFullDocuments,
    blockedByAlreadyTakenMerchantCode,
  } = useRNBSelector((state) => ({
    merchantCodes: state.merchantCodes.merchantCodes,
    editedFullDocument: state.inputMask.edited_full_document,
    initialFullDocument: state.inputMask.initial_full_document,
    deactivatedFullDocuments: state.inputMask.deactivated_full_documents,
    blockedByAlreadyTakenMerchantCode:
      state.inputMask.blockedByAlreadyTakenMerchantCode,
  }))

  const dispatch = useDispatch()

  const options = merchantCodes.map((m) => ({
    value: String(m.id),
    label: m.code,
  }))

  const selectedOption =
    options.find(
      (o) => Number(o.value) === editedFullDocument?.merchant_code_id
    ) || null

  const isCurrentDocumentBeingReactivated = deactivatedFullDocuments.some(
    (d) => d.fd_id === editedFullDocument?.fd_id
  )

  useEffect(() => {
    if (isCurrentDocumentBeingReactivated) {
      setIsModalDisplayed(true)
    }
  }, [isCurrentDocumentBeingReactivated])

  const isLocked =
    initialFullDocument?.merchant_id !== editedFullDocument?.merchant_id

  if (isLocked) {
    const displayedCode =
      merchantCodes.find((mc) =>
        mc.merchants.some(
          (m) => m.merchant_id === editedFullDocument?.merchant_id
        )
      )?.code || ""

    return (
      <LockedCellWrapper>
        {displayedCode}
        <StyledLock />
      </LockedCellWrapper>
    )
  }

  const alreadyTakenMerchantCode = merchantCodes.find(
    (m) =>
      m.id !== initialFullDocument?.merchant_code_id &&
      m.id === editedFullDocument?.merchant_code_id &&
      m.merchants.length > 0 &&
      m.centralize === false
  )

  return (
    <>
      <MerchantModificationModal
        isDisplayed={isModalDisplayed}
        onClose={() => {
          setIsModalDisplayed(false)
          dispatch(reinitializeMerchantModification())
        }}
        buyOrSell={editedFullDocument?.buy_or_sell || "buy"}
      />

      <AbsoluteWrapper>
        <StyledSelect
          disabled={
            isCurrentDocumentBeingReactivated &&
            !editedFullDocument?.merchant_code_id
          }
          error={
            Boolean(alreadyTakenMerchantCode) &&
            blockedByAlreadyTakenMerchantCode
          }
          height="8rem"
          intl={null}
          value={selectedOption}
          defaultValue={null}
          options={options}
          onChangeCallback={(e) => {
            if (!editedFullDocument) {
              return
            }

            dispatch(changeMerchantCode({ merchantCodeId: Number(e.value) }))
            dispatch(
              getInputMaskMerchantImpactedDocuments({
                companyId: selectedCompanyId,
                legalEntityId: editedFullDocument.legal_entity_id,
              })
            )

            if (
              merchantCodes.find(
                (m) =>
                  m.id === Number(e.value) &&
                  m.id !== initialFullDocument?.merchant_code_id &&
                  m.merchants.length > 0 &&
                  m.centralize === false
              )
            ) {
              dispatch(
                toggleBlockedByAlreadyTakenMerchantCode({
                  blockedByAlreadyTakenMerchantCode: true,
                })
              )
            }
          }}
          border="none"
          borderRadius="0"
          hasPlusButton={true}
        />
        <StyledEdit
          onClick={() => {
            setIsModalDisplayed(true)
          }}
        />
      </AbsoluteWrapper>
    </>
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
`
const StyledEdit = styled(Edit)`
  width: 2rem;
  height: 2rem;
  top: 3rem;
  right: 1rem;
  position: absolute;
  cursor: pointer;
  fill: ${colors.rock};
  transition: 0.5s fill ease-in-out;
  &:hover {
    fill: ${colors.cornflower};
  }
`
const AbsoluteWrapper = styled.div`
  position: relative;
  width: 100%;
`

const LockedCellWrapper = styled.div`
  position: relative;
  padding-right: 4rem;
`

const StyledLock = styled(Lock)`
  position: absolute;
  top: calc(50% - 1rem);
  right: 1rem;
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
  fill: ${colors.purple};
`
