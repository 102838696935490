import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { getBuyOrSellFromParams, getIdFromParams } from "../../utils/company"
import styled from "styled-components/macro"
import { colors, sizes } from "../../styles/design.config"
import { useEffect, useMemo, useState } from "react"
import { modifyMerchantThunk } from "../../store/ducks/merchants.ducks"
import { Select } from "../Commons/Select"
import { optionOfAccount } from "../../utils/merchants"
import { useRNBSelector } from "../../store/rootReducer"
import { ModifyMerchantBan } from "./ModifyMerchantBan"
import { Account } from "../../store/ducks/accounts.ducks"
import { useDispatch } from "react-redux"
import { capitalizeFirstLetter } from "../../utils/string"
import { Alert } from "../Commons/Alert"
import { Text } from "../Commons/Text"
import { Table, TableBuilder } from "../Commons/Table"
import {
  arraysHaveChanged,
  checkIfRowAlreadyExists,
  CreateKeyWord,
  getChangedRows,
  hasKeyWordDuplicates,
  KeyWord,
} from "../../utils/accountingKeyWords"
import { Input } from "../Commons/Input"
import { ReactComponent as TrashIcon } from "../../assets/TrashIcon.svg"
import { TableWrapper } from "../Commons/TableStylesForSelect"
import { ReactComponent as Warning } from "../../assets/warning.svg"
/* eslint-disable camelcase */

interface MerchantChangeModalProps extends Ct.ModalComponentsProps {
  merchantId: number
}
export const MerchantChangeModal = ({
  onClose,
  isDisplayed,
  merchantId,
}: MerchantChangeModalProps) => {
  const intl = useIntl()
  const buyOrSell = getBuyOrSellFromParams(useParams())
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const dispatch = useDispatch()

  const merchant = useRNBSelector(
    (state) => state.merchants.merchants[merchantId]
  )
  const saveStatus = useRNBSelector((state) => state.merchants.saveStatus)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]
  )
  const companyKeyWords = useRNBSelector(
    (state) => state.companySettings.accounting_key_words.key_words
  )
  const companyOnboarding = useRNBSelector(
    (state) => state.companySettings.isOnboardingFinished
  )

  const [merchantName, setMerchantName] = useState<string>(merchant.name)
  const standardLedger = useRNBSelector(
    (state) =>
      state.merchants.merchants[merchant.id].outputDisplay?.account_line
  )
  const keyWords = useRNBSelector(
    (state) => state.merchants.merchants[merchant.id].outputDisplay?.key_words
  )
  const merchantCodes = useRNBSelector((state) => state.merchants.merchantCodes)
  const availableAccounts = useRNBSelector((state) =>
    buyOrSell ? state.accounts.PNL[buyOrSell] : []
  )
  const softwareRules = useRNBSelector((state) => state.softwareRules.rules)
  const selectedFiscalYearId = useRNBSelector(
    (state) => state.fiscalYears.selectedFiscalYearId
  )
  const VATAccounts = useRNBSelector((state) =>
    buyOrSell === "buy"
      ? state.companySettings.possibleBuyAccounts
      : state.companySettings.possibleSellAccounts
  )

  const [code, setCode] = useState<Ct.Option<string>>({
    value: String(merchant.merchant_code.id),
    label: merchant.merchant_code.code,
  })
  const [defaultAccountOption, setDefaultAccountOption] = useState<
    Ct.Option<string>
  >({
    value: "",
    label: "",
  })
  const [defaultVATAccount, setDefaultVATAccount] = useState<Ct.Option<string>>(
    {
      value: "",
      label: "",
    }
  )
  const [bannedAccounts, setBannedAccounts] = useState(
    merchant.banned_accounts || []
  )
  const [compteNum, setCompteNum] = useState<string>("")
  const [compteLib, setCompteLib] = useState<string>("")
  const [auxNum, setAuxNum] = useState<string>("")
  const [auxLib, setAuxLib] = useState<string>("")
  const [keyWordsToDisplay, setKeyWordsToDisplay] = useState<CreateKeyWord[]>([
    {
      buy_or_sell: {
        value: "buy",
        label: capitalizeFirstLetter(
          intl.formatMessage({
            id: `buy-or-sell.buy`,
          })
        ),
      },
      key_word: "",
      account_root: null,
      create_or_update: "create",
      id: null,
    },
  ])
  const [hoverId, setHoverId] = useState<number | null>(null)

  const unbannedAccounts = Object.values(availableAccounts).filter(
    (a) => !bannedAccounts?.map((ba) => ba.id).includes(a.id)
  )
  const defaultAccountOptions = unbannedAccounts.map(optionOfAccount)

  const defaultVATAccountOptions: Ct.OptionList<string> = VATAccounts.map(
    (account) => ({
      value: account.id.toString(),
      label: `${account.number} ${account.details}`,
    })
  )

  const [keyWordsToDestroy, setKeyWordsToDestroy] = useState<CreateKeyWord[]>(
    []
  )
  const options: Ct.Option<string>[] = ["buy", "sell"].map((bs) => ({
    value: bs.toString(),
    label: capitalizeFirstLetter(
      intl.formatMessage({
        id: `buy-or-sell.${bs}`,
      })
    ),
  }))

  if (buyOrSell === "buy") {
    if (companyOnboarding === "finished") {
      defaultVATAccountOptions.unshift(
        { value: "lock_without_vat", label: "Sans TVA" },
        {
          value: "lock_eu_vat",
          label: "Autoliquidation TVA Intracommunautaire Services",
        },
        {
          value: "lock_eu_goods_vat",
          label: "Autoliquidation TVA Intracommunautaire Biens",
        },
        { value: "lock_construction_vat", label: "Autoliquidation BTP" },
        {
          value: "lock_world_vat",
          label: "Autoliquidation TVA Hors UE Services",
        },
        {
          value: "lock_world_goods_vat",
          label: "Autoliquidation TVA Hors UE Biens",
        }
      )
    }
  } else if (buyOrSell === "sell") {
    defaultVATAccountOptions.unshift({
      value: "lock_without_vat",
      label: "Sans TVA",
    })
  }

  const codesOptionsArray: [string, string][] = Object.values(
    merchantCodes
  ).map((c) => {
    return [String(c.id), c.code]
  })

  const codeOptions: Ct.OptionList<string> = useMemo(() => {
    return Ct.createOptionList(codesOptionsArray).map((o) => ({
      value: o.value,
      label: capitalizeFirstLetter(o.label),
    }))
  }, [merchantCodes])

  const isMerchantNameError = (name: string | undefined) =>
    !name ||
    name.length < 1 ||
    (softwareRules !== null &&
      softwareRules.merchant &&
      name.length > softwareRules.merchant.name)

  const merchantNameErrorText = (name: string | undefined) => {
    if (!name || name.length < 1) {
      return intl.formatMessage({
        id: `office.modify-merchant.${buyOrSell}.merchant-name.empty-name`,
      })
    } else if (
      softwareRules !== null &&
      softwareRules.merchant &&
      name.length > softwareRules.merchant.name
    ) {
      return intl.formatMessage({
        id: `office.modify-merchant.${buyOrSell}.merchant-name.name-too-long`,
      })
    }
    return "error"
  }

  const isMerchantEdited = () => {
    if (!buyOrSell || !merchant) {
      return false
    }

    const isAccountChanged =
      (merchant[`${buyOrSell}_default_account`]?.id || null) !==
      (defaultAccountOption
        ? defaultAccountOption.value === ""
          ? null
          : Number(defaultAccountOption.value)
        : null)

    const isVatAccountChanged =
      isNaN(Number(defaultVATAccount.value)) ||
      (defaultVATAccount.value === "" &&
        merchant[`${buyOrSell}_lock_vat_instructions`] !== "unlock")
        ? merchant[`${buyOrSell}_lock_vat_instructions`] !==
          (defaultVATAccount
            ? defaultVATAccount.value === ""
              ? null
              : defaultVATAccount.value
            : null)
        : merchant[`${buyOrSell}_vat_default_account_id`] !==
          (defaultVATAccount
            ? defaultVATAccount.value === ""
              ? null
              : Number(defaultVATAccount.value)
            : null)

    return (
      (merchant.name !== merchantName &&
        merchantName !== "" &&
        merchantName !== null) ||
      merchant.merchant_code.id !== Number(code.value) ||
      isAccountChanged ||
      JSON.stringify(bannedAccounts?.map((a) => a.id).sort()) !==
        JSON.stringify(merchant?.banned_accounts?.map((a) => a.id).sort()) ||
      isVatAccountChanged
    )
  }

  const updateMerchant = () => {
    if (selectedCompanyId && buyOrSell && selectedFiscalYearId) {
      const foundCode = Object.values(merchantCodes).find(
        (c) => c.id === Number(code.value)
      )

      const VATAccountValue =
        defaultVATAccount?.value === ""
          ? null
          : defaultVATAccount?.value === "lock_without_vat"
          ? "lock_without_vat"
          : defaultVATAccount?.value === "lock_eu_vat"
          ? "lock_eu_vat"
          : defaultVATAccount?.value === "lock_eu_goods_vat"
          ? "lock_eu_goods_vat"
          : defaultVATAccount?.value === "lock_world_vat"
          ? "lock_world_vat"
          : defaultVATAccount?.value === "lock_world_goods_vat"
          ? "lock_world_goods_vat"
          : defaultVATAccount?.value === "lock_construction_vat"
          ? "lock_construction_vat"
          : Number(defaultVATAccount?.value)

      const keyWordsToCreateUpdate = keyWords
        ? getChangedRows(
            keyWordsToDisplay.slice(0, -1),
            keyWords.map((kw) => ({
              buy_or_sell: {
                value: kw.buy_or_sell,
                label: capitalizeFirstLetter(
                  intl.formatMessage({
                    id: `buy-or-sell.${kw.buy_or_sell}`,
                  })
                ),
              },
              key_word: kw.key_word,
              account_root: kw.account_root,
              create_or_update: "update",
              id: kw.id,
            }))
          )
        : []

      dispatch(
        modifyMerchantThunk(selectedCompanyId)(buyOrSell)(merchant)(
          merchantName.trim()
        )(
          defaultAccountOption
            ? Number(defaultAccountOption.value)
              ? availableAccounts[Number(defaultAccountOption.value)]
              : null
            : null
        )(bannedAccounts)(foundCode || merchant.merchant_code)(VATAccountValue)(
          keyWordsToCreateUpdate
        )(keyWordsToDestroy)
      )
    }
  }

  const columnsMerchant: TableBuilder<CreateKeyWord>[] = [
    {
      headerText: "accounting-plan.key-words.table-header.buy-or-sell",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateKeyWord) => {
        const index = keyWordsToDisplay.indexOf(row)
        return (
          <Select
            intl={intl}
            options={options}
            value={row.buy_or_sell}
            onChangeCallback={(e: Ct.Option<string>) => {
              const index = keyWordsToDisplay.indexOf(row)
              const updatedKeyWord = [...keyWordsToDisplay]
              updatedKeyWord[index] = {
                ...row,
                buy_or_sell:
                  options.find((u) => u.value === e.value) || options[0],
              }
              setKeyWordsToDisplay(updatedKeyWord)
            }}
            label={""}
            domain={""}
            optionType={""}
            borderRadius={"0"}
            overrideBorder={`1px solid ${colors.grey} `}
            backgroundColor={
              hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
            }
          />
        )
      },
    },
    {
      headerText: "accounting-plan.key-words.table-header.key-word",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateKeyWord) => {
        const index = keyWordsToDisplay.indexOf(row)
        return (
          <Input
            label=""
            value={row.key_word}
            onChange={(e) => {
              const index = keyWordsToDisplay.indexOf(row)
              const updatedKeyWord = [...keyWordsToDisplay]
              updatedKeyWord[index] = {
                ...row,
                key_word: e.target.value,
              }
              setKeyWordsToDisplay(updatedKeyWord)
            }}
            borderRadius={0}
            borderColor={
              checkIfRowAlreadyExists(row, index, keyWordsToDisplay) === index
                ? colors.amaranth
                : colors.grey
            }
            backgroundColor={
              hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
            }
          />
        )
      },
    },
    {
      headerText: "accounting-plan.key-words.table-header.account-root",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateKeyWord) => {
        const index = keyWordsToDisplay.indexOf(row)
        return (
          <WrapperInput>
            <Input
              label=""
              value={String(row.account_root)}
              onChange={(e) => {
                const updatedKeyWord = [...keyWordsToDisplay]
                updatedKeyWord[index] = {
                  ...row,
                  account_root: Number(e.target.value),
                }
                setKeyWordsToDisplay(updatedKeyWord)
              }}
              borderRadius={0}
              borderColor={colors.grey}
              backgroundColor={
                hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
              }
              type={"number"}
              min={"1"}
            />
          </WrapperInput>
        )
      },
    },
    {
      headerText: "",
      width: "4%",
      flexGrow: "none",
      heightItem: "5.6rem",
      content: (row: CreateKeyWord) => {
        const index = keyWordsToDisplay.indexOf(row)
        return (
          <TrashBlock
            onMouseEnter={() => {
              if (index === keyWordsToDisplay.length - 1) return
              setHoverId(index)
            }}
            onMouseLeave={() => {
              if (index === keyWordsToDisplay.length - 1) return
              setHoverId(null)
            }}
            onClick={() => {
              if (index === keyWordsToDisplay.length - 1) return
              if (index > -1) {
                setKeyWordsToDestroy([...keyWordsToDestroy, row])

                const updatedKeyWord = [...keyWordsToDisplay]
                updatedKeyWord.splice(index, 1)
                setKeyWordsToDisplay(updatedKeyWord)
                setHoverId(null)
              }
            }}
            backgroundColor={
              hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
            }
          >
            <StyledTrashIcon
              disabled={index === keyWordsToDisplay.length - 1}
            />
          </TrashBlock>
        )
      },
    },
  ]

  const columnsCompany: TableBuilder<KeyWord>[] = [
    {
      headerText: "accounting-plan.key-words.table-header.buy-or-sell",
      width: "100%",
      flexGrow: "none",
      content: (row: KeyWord) => (
        <WrapperInputLike>
          <Text
            text={capitalizeFirstLetter(
              intl.formatMessage({
                id: `buy-or-sell.${row.buy_or_sell}`,
              })
            )}
          />
        </WrapperInputLike>
      ),
    },
    {
      headerText: "accounting-plan.key-words.table-header.key-word",
      width: "100%",
      flexGrow: "none",
      content: (row: KeyWord) => (
        <WrapperInputLike>
          <Text text={row.key_word} />
        </WrapperInputLike>
      ),
    },
    {
      headerText: "accounting-plan.key-words.table-header.account-root",
      width: "100%",
      flexGrow: "none",
      content: (row: KeyWord) => (
        <WrapperInputLike>
          <Text text={String(row.account_root)} />
        </WrapperInputLike>
      ),
    },
  ]

  const isKeyWordsChanged =
    keyWords &&
    arraysHaveChanged(
      keyWordsToDisplay.slice(0, -1),
      keyWords.map((kw) => ({
        buy_or_sell: {
          value: kw.buy_or_sell,
          label: capitalizeFirstLetter(
            intl.formatMessage({
              id: `buy-or-sell.${kw.buy_or_sell}`,
            })
          ),
        },
        key_word: kw.key_word,
        account_root: kw.account_root,
        create_or_update: "update",
        id: kw.id,
      }))
    ) &&
    !hasKeyWordDuplicates(keyWordsToDisplay)

  const isSaveDisabled = isMerchantEdited() || isKeyWordsChanged

  const close = () => {
    if (isSaveDisabled) {
      const warningMessage = `Vous êtes en train de modifier un ${
        buyOrSell === "buy" ? "fournisseur" : "client"
      }. \nSi vous quittez maintenant, les modifications ne seront pas sauvegardées`
      if (window.confirm(warningMessage)) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  useEffect(() => {
    if (keyWords && keyWords.length > 0) {
      setKeyWordsToDisplay(
        keyWords.map((kw) => ({
          buy_or_sell:
            options.find((o) => o.value === kw.buy_or_sell) || options[0],
          key_word: kw.key_word,
          account_root: kw.account_root,
          create_or_update: "update",
          id: kw.id,
        }))
      )
    }
  }, [keyWords])

  useEffect(() => {
    const lastItem: CreateKeyWord =
      keyWordsToDisplay[keyWordsToDisplay.length - 1]
    if (lastItem && lastItem.account_root) {
      setKeyWordsToDisplay([
        ...keyWordsToDisplay,
        {
          buy_or_sell: {
            value: "buy",
            label: capitalizeFirstLetter(
              intl.formatMessage({
                id: `buy-or-sell.buy`,
              })
            ),
          },
          key_word: "",
          account_root: null,
          create_or_update: "create",
          id: null,
        },
      ])
    }
  }, [keyWordsToDisplay])

  useEffect(() => {
    if (saveStatus === "success") {
      onClose()
    }
  }, [saveStatus])

  useEffect(() => {
    if (buyOrSell && merchant) {
      const selectedMerchantDefaultAccount =
        merchant[`${buyOrSell}_default_account`]

      if (selectedMerchantDefaultAccount?.id) {
        setDefaultAccountOption(optionOfAccount(selectedMerchantDefaultAccount))
      } else {
        setDefaultAccountOption({
          value: "",
          label: "",
        })
      }

      if (merchant[`${buyOrSell}_vat_default_account_id`]) {
        setDefaultVATAccount(
          defaultVATAccountOptions.find(
            (a) =>
              a.value ===
              String(merchant[`${buyOrSell}_vat_default_account_id`])
          ) || { value: "", label: "" }
        )
      } else {
        switch (merchant[`${buyOrSell}_lock_vat_instructions`]) {
          case "lock_without_vat":
            setDefaultVATAccount({
              value: "lock_without_vat",
              label: "Sans TVA",
            })
            break
          case "lock_eu_vat":
            setDefaultVATAccount({
              value: "lock_eu_vat",
              label: "Autoliquidation TVA Intracommunautaire Services",
            })
            break
          case "lock_eu_goods_vat":
            setDefaultVATAccount({
              value: "lock_eu_goods_vat",
              label: "Autoliquidation TVA Intracommunautaire Biens",
            })
            break
          case "lock_world_vat":
            setDefaultVATAccount({
              value: "lock_world_vat",
              label: "Autoliquidation TVA HORS UE Services",
            })
            break
          case "lock_world_goods_vat":
            setDefaultVATAccount({
              value: "lock_world_goods_vat",
              label: "Autoliquidation TVA HORS UE Biens",
            })
            break
          case "lock_construction_vat":
            setDefaultVATAccount({
              value: "lock_construction_vat",
              label: "Autoliquidation BTP",
            })
            break
          default:
            break
        }
      }
    }
  }, [merchant, buyOrSell])

  useEffect(() => {
    setCompteNum(standardLedger?.number || "")
    setCompteLib(standardLedger?.details || "")
    setAuxLib(standardLedger?.auxiliaryDetails || "")
    setAuxNum(standardLedger?.auxiliaryNumber || "")
  }, [standardLedger])

  useEffect(() => {
    if (merchantName && merchantName !== "" && merchantName !== merchant.name) {
      if (company.auxiliary) {
        setAuxLib(merchantName)
      } else {
        setCompteLib(merchantName)
      }
    }
  }, [merchantName])

  useEffect(() => {
    if (
      code &&
      code.value !== "" &&
      Number(code.value) !== merchant.merchant_code.id
    ) {
      if (company.auxiliary) {
        const newAuxNum = (standardLedger?.auxiliaryNumber || "")
          .toLowerCase()
          .replace(merchant.merchant_code.code.toLowerCase(), code.label)
        setAuxNum(newAuxNum.toUpperCase())
      } else {
        if (buyOrSell) {
          setCompteNum(
            "401" + company[`${buyOrSell}_auxiliary_prefix`] + code.label || ""
          )
        }
      }
    } else {
      setAuxNum(standardLedger?.auxiliaryNumber || "")
    }
  }, [code])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top="calc(25vh - 25rem)"
    >
      <Ct.Card width={"160rem"}>
        <Ct.CloseCross onClick={close} />
        <Ct.Title
          text={intl.formatMessage(
            { id: "ged.modal.edit-merchant.title" },
            { buyOrSell: buyOrSell === "buy" ? "Fournisseur" : "Client" }
          )}
          size={7}
        />
        <Ct.Spacer height={4} />

        <WrapperBlock>
          <WrapperLedger>
            <Header>
              <Ct.Text
                text={intl.formatMessage({
                  id: "ged.modal.standard-ledger.compteNum",
                })}
                textStyle={{
                  fontWeight: 700,
                }}
              />
              <Ct.Text
                text={intl.formatMessage({
                  id: "ged.modal.standard-ledger.compteLib",
                })}
                textStyle={{
                  fontWeight: 700,
                }}
              />
              <Ct.Text
                text={intl.formatMessage({
                  id: "ged.modal.standard-ledger.compteAuxNum",
                })}
                textStyle={{
                  fontWeight: 700,
                }}
              />
              <Ct.Text
                text={intl.formatMessage({
                  id: "ged.modal.standard-ledger.compteAuxLib",
                })}
                textStyle={{
                  fontWeight: 700,
                }}
              />
            </Header>
            <Ct.Spacer height={3} />
            <CardBody id={"cardMerchantStandardLedger"}>
              <Ct.Text text={compteNum || ""} />
              <Ct.Text text={compteLib || ""} />
              <Ct.Text text={auxNum} />
              <Ct.Text text={auxLib} />
            </CardBody>
            <Ct.Spacer height={3} />
          </WrapperLedger>

          <Ct.Spacer height={4} />

          <WrapperEdit>
            <ChangeBlock>
              <Ct.Text
                text={intl.formatMessage(
                  {
                    id: "ged.modal.edit-merchant.edit-name",
                  },
                  { buyOrSell: buyOrSell === "buy" ? "fournisseur" : "client" }
                )}
                textStyle={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              />

              <Ct.Spacer height={2} />

              <Ct.Input
                label={intl.formatMessage({
                  id: `office.modify-merchant.${buyOrSell}.merchant-name.placeholder`,
                })}
                value={merchantName}
                onChange={(event) =>
                  setMerchantName(event.target.value.replace(/\s\s+/g, " "))
                }
                showError={isMerchantNameError(merchantName)}
                name={"merchantName"}
              />

              {isMerchantNameError(merchantName) && (
                <StyledErrorText
                  text={merchantNameErrorText(merchantName)}
                  textStyle={{
                    color: "amaranth",
                    fontSize: 1.75,
                    lineHeight: 3,
                  }}
                  data-cy={"merchant-name-empty-name"}
                />
              )}
            </ChangeBlock>

            <Ct.Spacer width={4} />

            <ChangeBlock>
              <Ct.Text
                text={intl.formatMessage({
                  id: "ged.modal.edit-merchant.edit-code",
                })}
                textStyle={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              />

              <Ct.Spacer height={2} />

              <Select
                intl={intl}
                options={codeOptions}
                value={code}
                onChangeCallback={(selectedCode: Ct.Option<string>) => {
                  setCode(selectedCode)
                }}
                label={merchant.merchant_code.code}
                domain={"ged.merchants.code"}
                optionType={"dropdown"}
              />
            </ChangeBlock>
          </WrapperEdit>

          <Ct.Spacer height={4} />

          <WrapperEdit>
            <ChangeBlock>
              <Ct.Text
                text={intl.formatMessage(
                  {
                    id: "ged.modal.edit-merchant.edit-account",
                  },
                  { accountType: buyOrSell === "buy" ? "charge" : "produit" }
                )}
                textStyle={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              />

              <Ct.Spacer height={2} />

              {buyOrSell && (
                <Select
                  intl={intl}
                  optionType={"label"}
                  domain={`office.modify-merchant.${buyOrSell}.default-account`}
                  options={defaultAccountOptions}
                  value={defaultAccountOption}
                  isClearable={true}
                  onChangeCallback={(selectedAccount: Ct.Option<string>) => {
                    setDefaultAccountOption(selectedAccount)
                  }}
                />
              )}
            </ChangeBlock>

            <Ct.Spacer width={4} />

            <ChangeBlock>
              <Ct.Text
                text={intl.formatMessage({
                  id: "ged.modal.edit-merchant.edit-vat-account",
                })}
                textStyle={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              />

              <Ct.Spacer height={2} />

              {buyOrSell && (
                <Select
                  intl={intl}
                  optionType={"label"}
                  domain={`ged.modal.edit-merchant.vat-account`}
                  options={defaultVATAccountOptions}
                  value={defaultVATAccount}
                  isClearable={true}
                  onChangeCallback={(selectedAccount: Ct.Option<string>) => {
                    setDefaultVATAccount(selectedAccount)
                  }}
                />
              )}
            </ChangeBlock>
          </WrapperEdit>

          <Ct.Spacer height={4} />

          <WrapperBan>
            {buyOrSell && (
              <ModifyMerchantBan
                merchant={merchant}
                availableAccounts={availableAccounts}
                buyOrSell={buyOrSell}
                selectedDefaultAccountId={
                  defaultAccountOption
                    ? Number(defaultAccountOption.value)
                    : null
                }
                onChange={(bannedAccounts: Array<Account>) => {
                  setBannedAccounts(bannedAccounts)
                }}
              />
            )}
          </WrapperBan>

          <Ct.Spacer height={4} />

          <StyledText
            text={intl.formatMessage({
              id: "accounting-plan.key-words.title",
            })}
            textStyle={{
              fontFamily: "Poppins",
              color: "navy",
              fontWeight: 600,
              fontSize: "2.25",
              textTransform: "uppercase",
            }}
          />

          <Ct.Spacer height={2} />

          <Alert alertType={"bulb"} margin={"0 2rem "}>
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.key-words.alert",
              })}
            />
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.key-words.alert2",
              })}
            />
          </Alert>

          <Ct.Spacer height={3} />

          <TableWrapper height="initial">
            <Table
              intl={intl}
              columns={columnsMerchant}
              rows={keyWordsToDisplay}
              alignItems={"center"}
              width={"100%"}
              height={"initial"}
              padding={"0"}
              paddingRows={"0"}
              fontWeightTitle={600}
              alertMessage={"accounting-plan.key-words.table.empty"}
              sortableColumnsLength={0}
              customScrollBar={true}
              centerFirstItem={true}
            />
          </TableWrapper>
          <Ct.Spacer height={4} />

          <StyledText
            text={intl.formatMessage({
              id: "accounting-plan.key-words.merchant.company-title",
            })}
            textStyle={{
              fontFamily: "Poppins",
              color: "navy",
              fontWeight: 600,
              fontSize: "2.25",
              textTransform: "uppercase",
            }}
          />
          <Ct.Spacer height={2} />
          <Alert alertType={"bulb"} margin={"0 2rem "}>
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.key-words.merchant.company-alert",
              })}
            />
          </Alert>

          <Ct.Spacer height={3} />

          <TableWrapper height="initial">
            <Table
              intl={intl}
              columns={columnsCompany}
              rows={companyKeyWords}
              alignItems={"center"}
              width={"100%"}
              height={"initial"}
              padding={"0"}
              paddingRows={"0"}
              fontWeightTitle={600}
              alertMessage={"accounting-plan.key-words.table.empty"}
              sortableColumnsLength={0}
              customScrollBar={true}
              centerFirstItem={true}
            />
          </TableWrapper>
          <Ct.Spacer height={4} />

          <CenteredDiv>
            {keyWords &&
              (hasKeyWordDuplicates(keyWordsToDisplay) ? (
                <>
                  <Warning />
                  <Ct.Spacer width={1} />
                  <Text
                    text={intl.formatMessage({
                      id: "accounting-plan.key-words.table-header.events.duplicates",
                    })}
                    textStyle={{
                      color: "amaranth",
                      fontWeight: 600,
                    }}
                  />
                </>
              ) : (
                <Text
                  text={intl.formatMessage(
                    {
                      id:
                        keyWordsToDisplay.length - 1 - keyWords.length <= 1
                          ? `accounting-plan.key-words.table-header.count.one`
                          : "accounting-plan.key-words.table-header.count.multiple",
                    },
                    {
                      count:
                        keyWordsToDisplay.length - 1 - keyWords.length < 0
                          ? 0
                          : keyWordsToDisplay.length - 1 - keyWords.length,
                    }
                  )}
                />
              ))}
          </CenteredDiv>

          <Ct.Spacer height={4} />

          <Ct.JustifyCenter>
            <Ct.Button
              label={intl.formatMessage({
                id: "office.modify-merchant.cta",
              })}
              width={sizes.button.standard}
              onClick={updateMerchant}
              disabled={!isSaveDisabled}
              dataCy={"cta"}
            />
          </Ct.JustifyCenter>
        </WrapperBlock>
      </Ct.Card>
    </Ct.Modal>
  )
}

const WrapperLedger = styled.div`
  border-radius: 1.25rem;
  border: 1px solid ${colors.lavender};
`
const Header = styled.header`
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  width: initial;
  background-color: ${colors.lavender};
  padding: 2rem 3rem;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;

  && {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }
`
const CardBody = styled.div`
  padding: 0 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;

  && {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }
`
const WrapperEdit = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const ChangeBlock = styled.div`
  width: 100%;
`
const WrapperBan = styled.div``
const StyledErrorText = styled((props) => <Ct.Text {...props} />)`
  padding-left: 2rem;
`
const StyledText = styled((props) => <Text {...props} />)`
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
`
const WrapperBlock = styled.div`
  width: 80%;
  overflow: auto;
  height: 60vh;
`
const WrapperInput = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  transition: 0.5s background-color ease-in-out;
  display: flex;
  width: 100%;
`
const TrashBlock = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  border: 1px solid ${colors.grey};
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  transition: 0.5s background-color ease-in-out;
`
const StyledTrashIcon = styled(TrashIcon)<{ disabled: boolean }>`
  fill: ${(props) => (props.disabled ? colors.moon : colors.rock)};
  padding-left: 0.6rem;
  padding-top: 0.2rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: 0.5s fill ease-in-out;
  :hover {
    fill: ${(props) => (props.disabled ? colors.moon : colors.amaranth)};
  }
`
const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const WrapperInputLike = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${colors.grey};
  padding: 2rem;
`
