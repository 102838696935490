/* eslint-disable camelcase */
import React, { Suspense, useEffect, useState } from "react"
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { DateTime } from "luxon"
import { useParams } from "react-router-dom"

import { MerchantCodeSelectCell } from "./MerchantCodeSelectCell"
import { InvoiceTypeSelectCell } from "./InvoiceTypeSelectCell"
import { getIdFromParams } from "../../utils/company"
import { useRNBSelector } from "../../store/rootReducer"
import { sortFiscalYears } from "../../utils/fiscalYears"
import { useDispatch } from "react-redux"
import {
  toggleBlockedByAlreadyTakenMerchantCode,
  updateFullDocumentReview,
} from "../../store/ducks/inputMask.ducks"
import { formatAmount } from "../../utils/money"
import { colors } from "../../styles/design.config"
import { ReactComponent as Lock } from "../../assets/lock.svg"
import { ReactComponent as Bulb } from "../../assets/buttons/bulb.svg"
import { ReactComponent as Warning } from "../../assets/info-warning.svg"
import { Text } from "../../components/Commons/Text"
import { FiscalYear } from "../../store/ducks/fiscalYears.ducks"
import { dateToFullDocumentFormat, locale } from "../../utils/date"
import { Switch } from "../Commons/Switch"
import { InputDateRange } from "../Commons/InputDateRange"

const DatePicker = React.lazy(() => import("rsuite/DatePicker"))
const CustomProvider = React.lazy(() => import("rsuite/CustomProvider"))

export const FullDocumentInfo = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const [firstFyBeginExercise, setFirstFyBeginExercise] = useState<string>("")
  const [fiscalYearChangeWarning, setFiscalYearChangeWarning] =
    useState<FiscalYear | null>(null)

  const {
    editedFullDocument,
    editedWritingLines,
    initialFullDocument,
    companyFiscalYears,
    impactedDocuments,
    merchantCodes,
    blockedByAlreadyTakenMerchantCode,
    disableCentralizeCode,
  } = useRNBSelector((state) => ({
    editedFullDocument: state.inputMask.edited_full_document,
    initialFullDocument: state.inputMask.initial_full_document,
    editedWritingLines: state.inputMask.edited_writing_lines,
    initialWritinglines: state.inputMask.initial_writing_lines,
    impactedDocuments: state.inputMask.impacted_documents,
    companyFiscalYears:
      state.fiscalYears.fiscalYearsByCompanyId[selectedCompanyId],
    merchantCodes: state.merchantCodes.merchantCodes,
    blockedByAlreadyTakenMerchantCode:
      state.inputMask.blockedByAlreadyTakenMerchantCode,
    disableCentralizeCode:
      state.companies.companies[selectedCompanyId].disable_centralized_codes,
  }))

  const hasChangedMerchant =
    editedFullDocument?.merchant_id !== initialFullDocument?.merchant_id
  const hasChangedMerchantCode =
    editedFullDocument?.merchant_code_id !==
    initialFullDocument?.merchant_code_id

  useEffect(() => {
    if (companyFiscalYears !== undefined) {
      setFirstFyBeginExercise(
        sortFiscalYears(companyFiscalYears)[0].beginExercise
      )
    }
  }, [companyFiscalYears])

  useEffect(() => {
    setFiscalYearChangeWarning(null)
  }, [initialFullDocument?.fd_id])

  useEffect(() => {
    const initialFullDocumentFiscalYear = companyFiscalYears.find((fy) => {
      if (!initialFullDocument?.fd_document_date) {
        return false
      }
      const fd_document_date = new Date(initialFullDocument.fd_document_date)
      const beginExercise = new Date(fy.beginExercise)
      const endExercise = new Date(fy.endExercise)
      return (
        fd_document_date >= beginExercise && fd_document_date <= endExercise
      )
    })
    const selectedDateFiscalYear = companyFiscalYears.find((fy) => {
      if (!editedFullDocument?.fd_document_date) {
        return false
      }
      const editedDate = new Date(editedFullDocument?.fd_document_date)
      const beginExercise = new Date(fy.beginExercise)
      const endExercise = new Date(fy.endExercise)
      return editedDate >= beginExercise && editedDate <= endExercise
    })
    if (
      initialFullDocumentFiscalYear &&
      selectedDateFiscalYear &&
      initialFullDocumentFiscalYear.id !== selectedDateFiscalYear.id
    ) {
      setFiscalYearChangeWarning(selectedDateFiscalYear)
    } else {
      setFiscalYearChangeWarning(null)
    }
  }, [editedFullDocument?.fd_document_date])

  const vatIncludedAmount = editedWritingLines.find(
    (line) =>
      line.datum_type === "tax_included" ||
      line.datum_type === "tax_included_bill_of_exchange"
  )

  const today = DateTime.now().toJSDate().toISOString().substring(0, 10)
  const [maximumDocumentDate, setMaximumDocumentdate] = useState<string>(
    initialFullDocument?.fd_document_date || ""
  )

  useEffect(() => {
    if (
      +new Date(initialFullDocument?.fd_document_due_date || "") -
        +new Date(today) <
      0
    ) {
      setMaximumDocumentdate(initialFullDocument?.fd_document_due_date || "")
    } else {
      setMaximumDocumentdate(today)
    }
  }, [companyFiscalYears, initialFullDocument])

  useEffect(() => {
    if (!editedFullDocument?.fd_document_due_date) {
      setMaximumDocumentdate(today)
      return
    }

    if (
      +new Date(editedFullDocument.fd_document_due_date) - +new Date(today) <
      0
    ) {
      setMaximumDocumentdate(editedFullDocument.fd_document_due_date)
    } else {
      setMaximumDocumentdate(today)
    }
  }, [editedFullDocument?.fd_document_due_date])

  const firstLineColumns = [
    {
      headerName: `merchant-code.${
        editedFullDocument?.buy_or_sell || "loading"
      }`,
      content: <MerchantCodeSelectCell />,
    },
    {
      headerName: "document-date",
      content: (
        <Suspense fallback={<Ct.SpinningLoader />}>
          <CustomProvider locale={locale}>
            <DatePicker
              oneTap
              format="dd/MM/yyyy"
              value={
                editedFullDocument?.fd_document_date
                  ? new Date(editedFullDocument?.fd_document_date)
                  : undefined
              }
              disabledDate={(date: Date | undefined) => {
                if (!date) return false

                const dateOfTheDay = date.toISOString().substring(0, 10)
                const selectedDateTimestamp = new Date(dateOfTheDay).getTime()
                const maxDate = maximumDocumentDate
                  ? new Date(maximumDocumentDate)
                  : new Date()

                return (
                  new Date(firstFyBeginExercise).getTime() >
                    selectedDateTimestamp ||
                  selectedDateTimestamp > maxDate.getTime() ||
                  false
                )
              }}
              onChange={(newValue: Date | null) => {
                if (!editedFullDocument || !newValue) return
                dispatch(
                  updateFullDocumentReview({
                    ...editedFullDocument,
                    fd_document_date:
                      DateTime.fromJSDate(newValue).toFormat("yyyy-MM-dd"),
                  })
                )
              }}
              onClean={() => {
                if (!editedFullDocument) return

                dispatch(
                  updateFullDocumentReview({
                    ...editedFullDocument,
                    fd_document_date: "",
                  })
                )
              }}
            />
          </CustomProvider>
        </Suspense>
      ),
    },
    {
      headerName: "including-taxes",
      content: vatIncludedAmount ? (
        <LockedCellWrapper>
          {formatAmount(vatIncludedAmount.amount)}
          <StyledLock />
        </LockedCellWrapper>
      ) : null,
    },
    {
      headerName: "buy-or-sell",
      content: <InvoiceTypeSelectCell />,
    },
  ]

  const secondLineColumns = [
    {
      headerName: "periodicity",
      content: (
        <InputDateRange
          editable={false}
          cleanable={true}
          hideBoxShadow={true}
          fontFamily="Roboto"
          fontWeight="400"
          fontSize="14px"
          placement="bottomEnd"
          placeholder={intl.formatMessage({
            id: "input-mask.full-document.periodicity-none",
          })}
          value={
            editedFullDocument?.periodicity_date_start &&
            editedFullDocument?.periodicity_date_end
              ? [
                  new Date(editedFullDocument.periodicity_date_start),
                  new Date(editedFullDocument.periodicity_date_end),
                ]
              : null
          }
          onChange={(newValue) => {
            if (!editedFullDocument) return

            dispatch(
              updateFullDocumentReview({
                ...editedFullDocument,
                periodicity_date_start: dateToFullDocumentFormat(
                  newValue ? newValue[0] : null
                ),
                periodicity_date_end: dateToFullDocumentFormat(
                  newValue ? newValue[1] : null
                ),
              })
            )
          }}
        />
      ),
    },
    {
      headerName: "due-date",
      content: (
        <Suspense fallback={<Ct.SpinningLoader />}>
          <CustomProvider locale={locale}>
            <DatePicker
              oneTap
              format="dd/MM/yyyy"
              value={
                editedFullDocument?.fd_document_due_date
                  ? new Date(editedFullDocument?.fd_document_due_date)
                  : undefined
              }
              disabledDate={(date: Date | undefined) => {
                if (!date) return false

                const minimumDate = editedFullDocument?.fd_document_date
                  ? new Date(editedFullDocument?.fd_document_date)
                  : null
                const selectedDateTimestamp = date.getTime()
                return minimumDate
                  ? minimumDate.getTime() > selectedDateTimestamp
                  : false
              }}
              onChange={(newValue: Date | null) => {
                if (!editedFullDocument || !newValue) return

                dispatch(
                  updateFullDocumentReview({
                    ...editedFullDocument,
                    fd_document_due_date:
                      DateTime.fromJSDate(newValue).toFormat("yyyy-MM-dd"),
                  })
                )
              }}
              onClean={() => {
                if (!editedFullDocument) return

                dispatch(
                  updateFullDocumentReview({
                    ...editedFullDocument,
                    fd_document_due_date: "",
                  })
                )
              }}
            />
          </CustomProvider>
        </Suspense>
      ),
    },
    {
      headerName: "reference",
      content: (
        <StyledInput
          value={editedFullDocument?.fd_document_reference || ""}
          onChange={(e) => {
            if (!editedFullDocument) return

            dispatch(
              updateFullDocumentReview({
                ...editedFullDocument,
                fd_document_reference: e.target.value,
              })
            )
          }}
        />
      ),
    },
    {
      headerName: "original-batch-name",
      content: (
        <LockedCellWrapper>
          {editedFullDocument?.original_file_name}
          <StyledLock />
        </LockedCellWrapper>
      ),
    },
  ]

  const showErrorFirstLine = (c: (typeof firstLineColumns)[number]) => {
    if (c.headerName.includes("merchant-code")) {
      return !editedFullDocument?.legal_entity_id
    }
    if (c.headerName.includes("document-date")) {
      return !editedFullDocument?.fd_document_date
    }

    return false
  }

  const showErrorSecondLine = (c: (typeof secondLineColumns)[number]) => {
    if (c.headerName.includes("merchant-name")) {
      return Boolean(!editedFullDocument?.merchant_id)
    }
    if (c.headerName.includes("reference")) {
      return (
        editedFullDocument?.buy_or_sell === "sell" &&
        !editedFullDocument?.fd_document_reference
      )
    }

    return false
  }

  const alreadyTakenMerchantCode = merchantCodes.find(
    (m) =>
      m.id !== initialFullDocument?.merchant_code_id &&
      m.id === editedFullDocument?.merchant_code_id &&
      m.merchants.length > 0 &&
      m.centralize === false
  )

  return (
    <FullDocumentInfoWrapper>
      <StyledTableWrapper>
        <StyledTableHeader>
          {firstLineColumns.map((c) => (
            <SizedTitle
              key={c.headerName}
              text={intl.formatMessage({
                id: `input-mask.table-headers.${c.headerName}`,
              })}
              textStyle={{
                fontWeight: 700,
                fontSize: 1.75,
                textTransform: "uppercase",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            />
          ))}
        </StyledTableHeader>
        <StyledTableBody>
          <ContentLine>
            {firstLineColumns.map((c, i) => (
              <SizedContent
                key={c.headerName + "content"}
                showError={showErrorFirstLine(c)}
                borderBottomLeftRadius={i === 0}
              >
                {c.content}
              </SizedContent>
            ))}
          </ContentLine>
        </StyledTableBody>
      </StyledTableWrapper>

      <FiscalYearChange isDisplayed={!!fiscalYearChangeWarning}>
        <Warning />
        <Ct.Spacer width={0.5} />
        <Text
          text={intl.formatMessage(
            {
              id: "input-mask.fiscal-year-change",
            },
            {
              begin_exercise: fiscalYearChangeWarning?.beginExercise,
              end_exercise: fiscalYearChangeWarning?.endExercise,
            }
          )}
        />
      </FiscalYearChange>

      <ConfirmCentralize
        isDisplayed={
          Boolean(alreadyTakenMerchantCode) && hasChangedMerchantCode
        }
      >
        {Boolean(alreadyTakenMerchantCode) && hasChangedMerchantCode ? (
          <Flex>
            <Switch
              value={!blockedByAlreadyTakenMerchantCode}
              onToggle={() => {
                dispatch(
                  toggleBlockedByAlreadyTakenMerchantCode({
                    blockedByAlreadyTakenMerchantCode:
                      !blockedByAlreadyTakenMerchantCode,
                  })
                )
              }}
              onColor="shamrock"
              offColor="amaranth"
            />
            <Ct.Spacer />
            {blockedByAlreadyTakenMerchantCode ? (
              <Text
                text={intl.formatMessage(
                  {
                    id: disableCentralizeCode
                      ? `input-mask.${editedFullDocument?.buy_or_sell}.already-taken-code.warning-and-centralize`
                      : `input-mask.${editedFullDocument?.buy_or_sell}.already-taken-code.warning`,
                  },
                  {
                    merchantNames: alreadyTakenMerchantCode?.merchants
                      .map((mc) => mc.merchant_name.toUpperCase())
                      .join(", "),
                  }
                )}
                textStyle={{
                  color: "amaranth",
                }}
              />
            ) : (
              <Text
                text={intl.formatMessage({
                  id: `input-mask.${editedFullDocument?.buy_or_sell}.already-taken-code.confirm`,
                })}
              />
            )}
          </Flex>
        ) : null}
      </ConfirmCentralize>

      <MerchantImpactedDocument isDisplayed={hasChangedMerchantCode}>
        {impactedDocuments &&
        hasChangedMerchantCode &&
        !blockedByAlreadyTakenMerchantCode ? (
          <Flex>
            <StyledBulb />
            <Ct.Spacer width={1} />
            <Text
              text={intl.formatMessage(
                {
                  id:
                    impactedDocuments > 1
                      ? "input-mask.impacted-documents"
                      : "input-mask.impacted-document",
                },
                {
                  impactedDocuments: impactedDocuments,
                }
              )}
              textStyle={{ color: "purple", fontWeight: 700 }}
            />
          </Flex>
        ) : null}
      </MerchantImpactedDocument>

      <StyledTableWrapper>
        <StyledTableHeader>
          {secondLineColumns.map((c) => (
            <SizedTitle
              key={c.headerName}
              text={intl.formatMessage({
                id: `input-mask.table-headers.${c.headerName}`,
              })}
              textStyle={{
                fontWeight: 700,
                fontSize: 1.75,
                textTransform: "uppercase",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            />
          ))}
        </StyledTableHeader>
        <StyledTableBody>
          <ContentLine>
            {secondLineColumns.map((c) => (
              <SizedContent
                key={c.headerName + "content"}
                borderBottomLeftRadius={c.headerName.includes("merchant-name")}
                showError={showErrorSecondLine(c)}
              >
                {c.content}
              </SizedContent>
            ))}
          </ContentLine>
        </StyledTableBody>
      </StyledTableWrapper>

      <MerchantImpactedDocument isDisplayed={hasChangedMerchant}>
        <MerchantImpactedDocument isDisplayed={hasChangedMerchant}>
          {impactedDocuments && hasChangedMerchant ? (
            <Flex>
              <StyledBulb />
              <Ct.Spacer width={1} />
              <Text
                text={intl.formatMessage(
                  {
                    id:
                      impactedDocuments > 1
                        ? "input-mask.impacted-documents"
                        : "input-mask.impacted-document",
                  },
                  {
                    impactedDocuments: impactedDocuments,
                  }
                )}
                textStyle={{ color: "purple", fontWeight: 700 }}
              />
            </Flex>
          ) : null}
        </MerchantImpactedDocument>
      </MerchantImpactedDocument>
    </FullDocumentInfoWrapper>
  )
}

const FullDocumentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledTableHeader = styled(Ct.TableHeader)`
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`
const StyledTableBody = styled(Ct.TableBody)`
  padding: 0;
`
const StyledTableWrapper = styled(Ct.TableWrapper)`
  height: auto;
  box-shadow: 0px 4px 11px 7px #e5e5e540;
  border: none;
  border-radius: 1rem;
`
const SizedTitle = styled(Ct.StyledTitles)`
  flex: 1;
`
const ContentLine = styled.div`
  display: flex;
`
const SizedContent = styled.div<{
  showError?: boolean
  borderBottomLeftRadius?: boolean
}>`
  height: 8rem;
  box-sizing: border-box;
  flex: 1 0 25%;
  max-width: 25%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${colors.grey};

  border-bottom-left-radius: ${({ borderBottomLeftRadius }) =>
    borderBottomLeftRadius ? `1rem` : "auto"};

  border: ${({ showError }) =>
    showError ? `1px solid ${colors.amaranth}` : "auto"};

  :last-of-type {
    border-right: none;
  }
`

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  position: relative;
  padding-right: 2.6rem;
  outline: none;
  box-sizing: border-box;
  caret-color: ${colors.cornflower};
  text-align: center;

  transition: 0.5s background-color ease-in-out, 0.5s border ease-in-out;

  &:focus {
    border: 2px solid ${colors.cornflower};
  }
`

const StyledLock = styled(Lock)`
  position: absolute;
  top: calc(50% - 1rem);
  right: 1rem;
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
  fill: ${colors.navy};
`

const StyledBulb = styled(Bulb)`
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
  fill: ${colors.purple};
`

const LockedCellWrapper = styled.div`
  position: relative;
  padding-right: 4rem;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
`

const FiscalYearChange = styled.div<{ isDisplayed: boolean }>`
  height: ${({ isDisplayed }) => (isDisplayed ? "6rem " : "0rem")};
  opacity: ${({ isDisplayed }) => (isDisplayed ? "1" : "0")};
  transition: 0.5s height ease-in-out;
  display: flex;
  align-items: center;
`

const MerchantImpactedDocument = styled.div<{ isDisplayed: boolean }>`
  height: ${({ isDisplayed }) => (isDisplayed ? "6rem " : "2rem")};
  transition: 0.5s height ease-in-out;
  display: flex;
  align-items: center;
`
const ConfirmCentralize = styled.div<{ isDisplayed: boolean }>`
  height: ${({ isDisplayed }) => (isDisplayed ? "8rem " : "2rem")};
  transition: 0.5s height ease-in-out;
  display: flex;
  align-items: flex-end;
`
